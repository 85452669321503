document.addEventListener("turbolinks:load", () => {
  const btn = document.querySelector("#navbar-btn-expand")
  const container = document.querySelector("main#content > div")
  if(!btn || !container) return;

  btn.addEventListener("click", () => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('exp='))
      ?.split('=')[1]
    
    // 5y: 60*60*24*365*5 = 157680000
    if(cookieValue == "" || cookieValue == undefined) {
      document.cookie = "exp=1; max-age=157680000; path=/"
      container.classList.add("container-fluid")
      container.classList.remove("container-xxl")
      btn.classList.add("bi-arrows-angle-contract")
      btn.classList.remove("bi-arrows-angle-expand")
    } else {
      document.cookie = "exp=; path=/"
      container.classList.add("container-xxl")
      container.classList.remove("container-fluid")
      btn.classList.add("bi-arrows-angle-expand")
      btn.classList.remove("bi-arrows-angle-contract")
    }
  })

})