document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'tracabilitat' && 
      ['index'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const sel_dt_cad = document.querySelector("#sel-dt-caducitat")
  const boto_dt_cad_avui = document.querySelector("#boto-dt-caducitat-avui")
  const boto_dt_cad_x = document.querySelector("#boto-dt-caducitat-x")
  
  boto_dt_cad_avui.addEventListener("click", (ev) => {
    sel_dt_cad._flatpickr.setDate("today")
  })
  boto_dt_cad_x.addEventListener("click", (ev) => {
    sel_dt_cad._flatpickr.setDate()
  })



})