const SideEffectsFlagPlugin = require("webpack/lib/optimize/SideEffectsFlagPlugin")

document.addEventListener("turbolinks:load", () => {
  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'rutes' && 
         ['new', 'create', 'edit', 'update'].includes(body_elem.dataset['action']) 
    )  ) {
    return
  }

  const drag_start = (ev) => {
    elem_drag = ev.target
    elem_over = ""
    const items_llista = llista.querySelectorAll(selector_items_llista)
    items_llista.forEach( (el) => { 
      el.style.transform = "" 
      el.style.transition = "transform .3s"
    })
    elem_drag.style.backgroundColor = "#ccffcc"
  }
  const drag_end = (ev) => {
    elem_drag.style.transition = "transform .3s, background-color 3s"
    elem_drag.style.backgroundColor = "";
    if(elem_over != "") { elem_over.style.transform = "" }
  }
  const elem_drag_enter = (ev) => {
    if(ev.currentTarget != elem_over) {
      if(elem_over != "") { elem_over.style.transform = "" }
      elem_over = ev.currentTarget
    }
  }
  const elem_drag_over = (ev) => {
    ev.preventDefault();
    if(elem_over == elem_drag) { return }
    if(ev.offsetY < ev.currentTarget.clientHeight / 2) {
      ev.currentTarget.style.transform = "translate(0,33%)";
      if(elem_over.previousSibling != elem_drag) {
        elem_over.parentNode.insertBefore(elem_drag, elem_over)
        reordenar()
      }
    } else {
      ev.currentTarget.style.transform = "translate(0,-33%)";
      if(elem_over.nextSibling != elem_drag) {
        elem_over.parentNode.insertBefore(elem_drag, elem_over.nextSibling) // "insert AFTER"
        reordenar()
      }
    }
  }
  const get_previ = (el) => {
    let pr = el.previousElementSibling
    while(pr) {
      if(pr.matches('div')) return pr
      pr = pr.previousElementSibling
    }
  }
  const get_proper = (el) => {
    let pr = el.nextElementSibling
    while(pr) {
      if(pr.matches('div')) return pr
      pr = pr.nextElementSibling
    }
  }
  const mou_up = (ev) => {
    mou(ev, "up")
  }
  const mou_down = (ev) => {
    mou(ev, "down")
  }
  const mou = (ev, dir) => {
    let orig = ev.target.closest(`${selector_llista} .wrapper-cli`)
    orig.style.transition = "transform .3s"
    orig.style.backgroundColor = "#ccffcc"
    orig.style.transition = "transform .3s, background-color 3s"
    if (dir == "up") 
    {
      let previ = get_previ(orig)
      if(previ) {
        previ.parentNode.insertBefore(orig, previ)
        reordenar()
      }
    } 
    else if (dir == "down") 
    {
      let proper = get_proper(orig)
      if(proper) {
        proper.parentNode.insertBefore(orig, proper.nextSibling)
        reordenar()
      }
    }
    setTimeout(() => {
      orig.style.backgroundColor = "";
    }, 50)
  }

  const reordenar = () => {
    const items_llista = llista.querySelectorAll(selector_items_llista)

    let ord = 1
    items_llista.forEach( (el) =>  {
      const inp_ord = el.querySelector("input[id$='_ordre']")
      const m_ord = el.querySelector(".mostra-ordre")
      if(inp_ord == null || m_ord == null) { return; }
      inp_ord.value = ord
      m_ord.textContent = ord
      ord += 1
    })
  }

  const activar_ord = () => {
    const items_llista = llista.querySelectorAll(selector_items_llista)
    items_llista.forEach( (el) => { 
      if(el.querySelector("input[id$='_ordre']") == null) { return; }
      el.draggable = true 
    })
    const botons_afegir = document.querySelectorAll(selector_botons_afegir)
    botons_afegir.forEach( (el) => el.classList.add('disabled') )
    //llista.classList.add('border-top')
    items_llista.forEach( (el) => {
      if(el.querySelector("input[id$='_ordre']") == null) { return; }
      el.querySelectorAll(".vanilla-nested-remove").forEach( (x) => x.classList.add('disabled') )
      el.querySelectorAll(".bi").forEach( (x) => x.classList.remove('d-none') )
      el.querySelectorAll("select").forEach( (x) => x.disabled = true )
      //el.classList.add('border-top-0', 'border')
      el.classList.add('border')
      el.addEventListener('dragenter', elem_drag_enter)
      el.addEventListener('dragover', elem_drag_over)
      el.querySelector(".bi-arrow-up").addEventListener('click', mou_up)
      el.querySelector(".bi-arrow-down").addEventListener('click', mou_down)
    })
    document.addEventListener('dragstart', drag_start)
    document.addEventListener('dragend', drag_end)
  }
  const desactivar_ord = () => {
    const items_llista = llista.querySelectorAll(selector_items_llista)
    items_llista.forEach( (el) =>  { 
      if(el.querySelector("input[id$='_ordre']") == null) { return; }
      el.draggable = false 
    })
    const botons_afegir = document.querySelectorAll(selector_botons_afegir)
    botons_afegir.forEach( (el) => el.classList.remove('disabled') )
    //llista.classList.remove('border-top')
    items_llista.forEach( (el) => {
      if(el.querySelector("input[id$='_ordre']") == null) { return; }
      el.querySelectorAll(".vanilla-nested-remove").forEach( (x) => x.classList.remove('disabled') )
      el.querySelectorAll(".bi").forEach( (x) => x.classList.add('d-none') )
      el.querySelectorAll("select").forEach( (x) => x.disabled = false )
      //el.classList.remove('border', 'border-top-0')
      el.classList.remove('border')
      el.removeEventListener('dragenter', elem_drag_enter)
      el.removeEventListener('dragover', elem_drag_over)
      el.querySelector(".bi-arrow-up").removeEventListener('click', mou_up)
      el.querySelector(".bi-arrow-down").removeEventListener('click', mou_down)
    })
    document.removeEventListener('dragstart', drag_start)
    document.removeEventListener('dragend', drag_end)
  }
  const canvi_switch_ord = (ev) => {
    if(ev.target.checked) {
      activar_ord()
    } else {
      desactivar_ord()
    }
  }
  const form_ruta_submit = (ev) => {
    if(switch_ord.checked) {
      switch_ord.checked = false
      const e = new Event("change")
      switch_ord.dispatchEvent(e)
    }
  }
  
  let elem_drag, elem_over
  let llista, switch_ord
  const init_llista_ord = () => {
    switch_ord = document.querySelector(selector_switch)
    llista = document.querySelector(selector_llista)
    const form_ruta = document.querySelector(selector_form_ruta)
    if(switch_ord == null || llista == null || form_ruta == null) { 
      console.log("Error inicialitzant") 
      return 
    }
    switch_ord.addEventListener('change', canvi_switch_ord)
    form_ruta.addEventListener('submit', form_ruta_submit)
  }
  let selector_switch = ".switch-ordenable"
  let selector_llista = "#rutes-clients"
  let selector_items_llista = ":scope > div"
  let selector_botons_afegir = "[data-container-selector='#rutes-clients']"
  let selector_form_ruta = "#form-ruta"
  init_llista_ord();

  const rt_cli = document.querySelector("#rutes-clients")
  rt_cli.addEventListener('vanilla-nested:fields-added', (ev) => { 
    ev.detail.added.scrollIntoView()
    reordenar()
  })
  rt_cli.addEventListener('vanilla-nested:fields-removed', (ev) => { 
    ev.target.classList.remove("fix-vores")
    reordenar() 
  })

  const datalist_clients = document.querySelector("#datalist-clients")
  const cerca_clients_input = document.querySelector("#cerca-clients-input")
  const selectors_clients = document.querySelectorAll(".wrapper-cli")
  cerca_clients_input.addEventListener("input", (ev) => {
    let s = ev.target.value.toLowerCase()
    let idcli = null
    for(let i = 0; i<datalist_clients.options.length; i+=1) {
      if(datalist_clients.options[i].dataset.nomlc == s)
      {
        idcli = datalist_clients.options[i].dataset.idcli
      }
    }
    if(idcli != null) {
      for(let i = 0; i<selectors_clients.length; i+=1) {
        let el = selectors_clients[i]
        let sel = el.querySelector("select")
        if(sel.value == idcli) {
          el.scrollIntoView()
        }
      }
    }
  })
  const row_hdr_clients = document.querySelector("#row-hdr-clients")
  row_hdr_clients.addEventListener('dragover', (ev) => {
    if(row_hdr_clients.classList.contains('row-hdr-stuck')) {
      window.scrollBy(0, -60)
    }
  })

  window.addEventListener('scroll', (ev) => {
    if(row_hdr_clients.getBoundingClientRect().top <= 138) {
      row_hdr_clients.classList.add('row-hdr-stuck')
    } else {
      row_hdr_clients.classList.remove('row-hdr-stuck')
    }
  })

})