document.addEventListener("turbolinks:load", () => {
  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'configuracio' && 
         ['show'].includes(body_elem.dataset['action']) ) ) {
    return
  }

  const img_logo = document.querySelector("#img-logo")
  const dimensions_img_logo = document.querySelector("#dimensions-img-logo")
  if (img_logo && dimensions_img_logo) {
    let img = new Image()
    img.src = img_logo.src
    img.addEventListener("load", () => {
      dimensions_img_logo.innerHTML = `${img.naturalWidth} x ${img.naturalHeight} px - `
    })
  }

  const opcions_smtp = document.querySelector("#opcions-smtp")
  opcions_smtp.addEventListener('shown.bs.collapse', (ev) => {
    ev.target.scrollIntoView()
  })

})