document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'liquidacio' && 
      ['show'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const sel_rep = document.querySelector("#sel-repartidor")
  const sel_dt_ini = document.querySelector("#sel-data-inicial")
  const sel_dt_fi = document.querySelector("#sel-data-final")
  const elems_form_auto_submit = [sel_rep, sel_dt_ini, sel_dt_fi]
  
  elems_form_auto_submit.forEach( elem => {
    elem.addEventListener("change", ev => {
      ev.currentTarget.closest("form").submit()
    })
  })

  const boto_inici_avui = document.querySelector("#boto-inici-avui")
  const boto_final_avui = document.querySelector("#boto-final-avui")
  boto_inici_avui.addEventListener("click", (ev) => {
    sel_dt_ini.value = "today"
    ev.currentTarget.closest("form").submit()
  })
  boto_final_avui.addEventListener("click", (ev) => {
    sel_dt_fi.value = "today"
    ev.currentTarget.closest("form").submit()
  })

})