document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'clients' && 
      ['index'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const sw_rutes = document.querySelector("#sw-rutes")
  const info_direccio = document.querySelectorAll(".info-direccio")
  const info_rutes = document.querySelectorAll(".info-rutes")
  const form_r = document.querySelector("#r")
  const links_pag = document.querySelectorAll(".page-link")

  const modif_links = (val) => {
    links_pag.forEach( el => {
      let url = new URL(el.href, window.location.origin);
      url.searchParams.set('r', val ? "" : "0")
      el.href = url
    })
  }

  sw_rutes.addEventListener('change', (ev) => {
    modif_links(ev.target.checked)
    form_r.value = ev.target.checked ? "" : "0"
    info_direccio.forEach( el => {
      if (ev.target.checked) {
        el.classList.add('d-none')
        el.classList.add('d-lg-table-cell')
      } else { 
        el.classList.remove('d-none')
        el.classList.remove('d-lg-table-cell')
      }
    })
    info_rutes.forEach( el => {
      ev.target.checked ? el.classList.remove('d-none') : el.classList.add('d-none')
    })
  })

})