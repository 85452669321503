document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'articles' && 
      ['index'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const elems_chkbox = document.querySelectorAll(".submit-al-canviar")
  elems_chkbox.forEach( elem => {
    elem.addEventListener("change", ev => {
      ev.currentTarget.blur()
      ev.currentTarget.closest("form").submit()
    })
  })

})