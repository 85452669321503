document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'carregues' && 
      ['new', 'edit', 'create', 'update'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const article_canviat = (ev) => {
    const art = ev.currentTarget
    const row = ev.currentTarget.parentElement.parentElement;
    const qty_carregada = row.querySelector("input.qty_carregada")
    
    if(art.value != "") {
      // assignar preu unitari, iva, quantitat inicial
      if(qty_carregada.value == "") {
        qty_carregada.value = 1
      }
    }
    qty_carregada.focus()
    qty_carregada.select()
    calc_totals()
  }
  const es_numeric = (s) => {
    return !isNaN(parseFloat(s)) && isFinite(s);
  }
  const arrodoneix = (n, m) => {
    let eps = (n>=0) ? Number.EPSILON : -Number.EPSILON
    return Math.round( (n+eps) * Math.pow(10, m)) / Math.pow(10, m)
  }

  const calc_totals = () => {
    const row_totals = document.querySelector("#row-totals")
    const lins_document = document.querySelector("#lins-document")
    if (lins_document.querySelector("div.row-cont select") == null) {
      // no mostrar totals si cap row-cont té select -> estan buits o amb elements eliminats
      row_totals.classList.add("d-none")
      return
    } else {
      row_totals.classList.remove("d-none")
    }

    // mostrar botó afegir inferior si hi han més de 2 línies
    const btn_afegir_inferior = document.querySelector("#btn-afegir-inferior")
    const sel_rows_ok = lins_document.querySelectorAll("div.row-cont select")
    if (btn_afegir_inferior != null) {
      if(sel_rows_ok != null && sel_rows_ok.length > 2) {
        btn_afegir_inferior.classList.remove("d-none")
      } else {
        btn_afegir_inferior.classList.add("d-none")
      }
    }

    const total_carregat = document.querySelector("#total-carregat")
    const total_descarregat = document.querySelector("#total-descarregat")
    let tot_carr = 0
    let tot_desc = 0
    lins_document.querySelectorAll("div.row-cont").forEach( el => {
      const qty_carr = el.querySelector("input.qty_carregada")
      const qty_desc = el.querySelector("input.qty_descarregada")
      if(qty_carr != null)
        tot_carr += es_numeric(qty_carr.value) ? Number(qty_carr.value) : 0
      if(qty_desc != null)
        tot_desc += es_numeric(qty_desc.value) ? Number(qty_desc.value) : 0

    total_carregat.textContent = arrodoneix(tot_carr, 2).toFixed(2)
    total_descarregat.textContent = arrodoneix(tot_desc, 2).toFixed(2)
    })
  }

  const l_doc = document.querySelector("#lins-document")
  l_doc.addEventListener('vanilla-nested:fields-added', function(e){
    const row_afegit = e.detail.added
    const article = row_afegit.querySelector(".article")
    const qty_carr = row_afegit.querySelector(".qty_carregada")
    const qty_desc = row_afegit.querySelector(".qty_descarregada")
    article.addEventListener("change", article_canviat)
    qty_carr.addEventListener("input", calc_totals)
    qty_desc.addEventListener("input", calc_totals)
    row_afegit.scrollIntoView()
    calc_totals()
  })
  l_doc.addEventListener('vanilla-nested:fields-removed', function(e){
    calc_totals()
  })


  // rows existents -> s'han d'afegir listeners
  document.querySelectorAll(".row-cont").forEach( el => {
    const article = el.querySelector(".article")
    const qty_carr = el.querySelector(".qty_carregada")
    const qty_desc = el.querySelector(".qty_descarregada")
    article.addEventListener("change", article_canviat)
    qty_carr.addEventListener("input", calc_totals)
    qty_desc.addEventListener("input", calc_totals)
  })

  calc_totals()
})