document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'usuaris' && 
         ['show'].includes(body_elem.dataset['action']) ) ) {
    return
  }

  const ico_copiar = document.querySelector("#icona-copiar")
  const dades_token = document.querySelector("#dades-token")

  if(ico_copiar && dades_token) {
    ico_copiar.addEventListener('click', () => {
      const clipboard = navigator.clipboard
      if(clipboard == undefined) {
        dataConfirmModal
        alert("Cal [SecureContext]: https:// o localhost")
        return
      }
      ico_copiar.classList.remove('bi-clipboard')
      ico_copiar.classList.add('bi-clipboard-check')
      navigator.clipboard.writeText(dades_token.textContent)
    })
  }
})

