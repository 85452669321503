document.addEventListener("turbolinks:load", () => {
  
  document.querySelectorAll('a.nav-link.active').forEach( (el) => {
    el.classList.remove('active') 
  })

  let url_orig = location.pathname
  let url_base = ""

  if(location.pathname.split('/').length > 1) {
    url_base = "/" + location.pathname.split('/')[1]
  }
  
  let regla_orig = `a.nav-link[href='${url_orig}']`
  let regla_base = `a.nav-link[href='${url_base}']`

  document.querySelectorAll(`${regla_orig}, ${regla_base}`).forEach( (el) => {
    el.classList.add('active')
  })
})
