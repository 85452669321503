// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import flatpickr from 'flatpickr';
import "flatpickr/dist/flatpickr.min.css"
import { Catalan } from "flatpickr/dist/l10n/cat.js"
import 'bootstrap-icons/font/bootstrap-icons.css'

import "../stylesheets/application"

import "vanilla-nested"


require('../src/albarans-preus-i-totals')
require('../src/articles-favorits-i-comissiona')
require('../src/carregues-form')
require('../src/carregues-index')
require('../src/clients-rutes')
require('../src/configuracio')
require('../src/copiar_token_usuari')
require('../src/data-confirm-modal')
require('../src/families-articles')
require('../src/header-informacio')
require('../src/liquidacio')
require('../src/nav_actiu')
require('../src/navbar-btn-expand')
require('../src/navbar-btn-text')
require('../src/ordenable-elems')
require('../src/pagina-inici')
require('../src/taula_click')
require('../src/tracabilitat')


Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
  $('[data-bs-toggle="popover"]').popover()
  $('[data-bs-toggle="tooltip"]').tooltip()

  flatpickr(".datepicker", {
    "locale" : Catalan,
    "dateFormat": "Y-m-d",
    "allowInput" : false,
    "altInput" : true,
    "altFormat" : "d-m-Y",
  })

  flatpickr(".datepicker-hhmm", {
    "locale" : Catalan,
    "dateFormat": "Y-m-d H:i",
    "allowInput" : false,
    "altInput" : true,
    "altFormat" : "d-m-Y H:i",
    "enableTime": true,
    "time_24hr": true,
  })

  dataConfirmModal.setDefaults({
    title: 'Confirmeu l\'acció',
    commit: 'Continuar',
    cancel: 'Cancel·lar'
  });
})

