document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( body_elem.dataset['controller'] == 'carregues' && 
      ['index'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const sel_rep = document.querySelector("#sel-repartidor")
  const elems_form_auto_submit = [sel_rep]
  
  elems_form_auto_submit.forEach( elem => {
    if(elem != null) {
      elem.addEventListener("change", ev => {
        ev.currentTarget.closest("form").submit()
      })
    }
  })


})