document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( ['families_articles'].includes(body_elem.dataset['controller']) && 
      ['index'].includes(body_elem.dataset['action']) ) {

    const chk_nomes_amb_fam = document.querySelector("#chk_nomes_amb_fam")
    const vis_rows_sense = document.querySelectorAll("#vis tr.sense_fam")
    chk_nomes_amb_fam.addEventListener("change", (ev) => {
      if(chk_nomes_amb_fam.checked) {
        vis_rows_sense.forEach( e => { e.classList.add("d-none") })
      } else {
        vis_rows_sense.forEach( e => { e.classList.remove("d-none") })
      }
    })
  }


  if( !( ['families_articles'].includes(body_elem.dataset['controller']) && 
         ['new', 'create', 'edit', 'update'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const art_fam = document.querySelector("#articles-families")

  const afegir_eliminar = () => {
    // mostrar botó afegir inferior si hi han més de 2 línies
    const btn_afegir_inferior = document.querySelector("#btn-afegir-inferior")
    const sel_rows_ok = art_fam.querySelectorAll("div.row-cont select")
    if (sel_rows_ok != null && sel_rows_ok.length > 2) {
      btn_afegir_inferior.classList.remove("d-none")
    } else {
      btn_afegir_inferior.classList.add("d-none")
    }
  }

  art_fam.addEventListener('vanilla-nested:fields-added', function(e){
    afegir_eliminar()
  })
  art_fam.addEventListener('vanilla-nested:fields-removed', function(e){
    afegir_eliminar()
  })

  afegir_eliminar()
})