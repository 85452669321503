import { Catalan } from "flatpickr/dist/l10n/cat.js"
document.addEventListener("turbolinks:load", () => {

  const body_elem = document.querySelector('body')
  if( !( ['albarans', 'comandes'].includes(body_elem.dataset['controller']) && 
         ['new', 'create', 'edit', 'update'].includes(body_elem.dataset['action']) 
    )) {
    return
  }

  const data_alb = document.querySelector("#albara_dt_creacio")
  const data_com = document.querySelector("#comanda_dt_creacio")
  let data_ctrl = null
  if(data_alb != null) {
    data_ctrl = data_alb
  } else if (data_com != null) {
    data_ctrl = data_com
  }
  if(data_ctrl != null) {
    data_ctrl.addEventListener("change", (ev) => {
      dataConfirmModal.confirm({
        text: 'Cal refrescar per actualitzar els preus a la data.\n\nEs perdran els canvis no desats. Refrescar?',
        zIindex: 10099,
        onConfirm: function() { 
          let url = new URL(document.location)
          url.searchParams.set("dt", data_ctrl.value)
          location = url
        }
      });
      // Rails.ajax({
      //   type: 'GET',
      //   url: '/albarans',
      //   dataType: 'script',
      // })
    })
  }


  const article_canviat = (ev) => {
    const art = ev.currentTarget
    const row = ev.currentTarget.parentElement.parentElement;
    const preu_unit = row.querySelector("input.preu-unitat")
    const quantitat = row.querySelector("input.quantitat")
    const pct_iva = row.querySelector("input.pct-iva")

    const lot = row.querySelector("input.lot")
    const dades_lot_cad = row.querySelector(".dades-lot-cad")
    const dt_cad = row.querySelector("input.dt_caducitat")
    let vista_lot_cad = false
    if (lot != null && dades_lot_cad != null && dt_cad != null){
      vista_lot_cad = true
    }
    
    if(art.value != "") {
      // assignar preu unitari, iva, quantitat inicial
      preu_unit.value = art.options[art.selectedIndex].getAttribute('data-preu-cli')
      pct_iva.value = art.options[art.selectedIndex].getAttribute('data-pct-iva')
      if(quantitat.value == "") {
        quantitat.value = 1
      }

      if(vista_lot_cad) {
        const mode_lot_cad = art.options[art.selectedIndex].getAttribute('data-mode-lot-cad')
        const dies_cad = art.options[art.selectedIndex].getAttribute('data-dies-caducitat')
        if(mode_lot_cad == "auto") {
          let avui = new Date()
          const dt_creacio = document.querySelector("#albara_dt_creacio")
          if(dt_creacio != null && dt_creacio._flatpickr != null) {
            const dt_cre_sel = dt_creacio._flatpickr.selectedDates[0]
            if(dt_cre_sel != undefined) {
              avui = dt_cre_sel
            }
          }

          const num_dies_cad = parseInt(dies_cad)
          const yyyy = avui.getFullYear()
          const mm = ("0" + (avui.getMonth() + 1)).slice(-2)
          const dd = ("0" + avui.getDate()).slice(-2)
          lot.value = `${yyyy}${mm}${dd}`
          const dt_cad_calc = new Date()
          dt_cad_calc.setDate( avui.getDate() + num_dies_cad )
          dt_cad._flatpickr.setDate( dt_cad_calc )
        } else {
          lot.value = ""
          dt_cad._flatpickr.setDate()
        }

        // mostrar row amb camps lot i caducitat
        if(mode_lot_cad != "no") 
        {
          dades_lot_cad.classList.remove("d-none")
        } else {
          dades_lot_cad.classList.add("d-none")
        }
      }
    } else {
      // valors per def si no article seleccionat
      preu_unit.value = 0
      if(vista_lot_cad) {
        lot.value = ""
        dt_cad._flatpickr.setDate()
        dades_lot_cad.classList.add("d-none")
      }
    }
    quantitat.focus()
    quantitat.select()
    calc_preus(row)
  }

  const qty_canviada = (ev) => {
    const row = ev.currentTarget.parentElement.parentElement;
    calc_preus(row)
  }

  const preu_unitat_canviat = (ev) => {
    const row = ev.currentTarget.parentElement.parentElement;
    calc_preus(row)
  }

  const sep_decimal = (s) => {
    return s == null ? "" : s.replace(",", ".")
  }

  const es_numeric = (s) => {
    return !isNaN(parseFloat(s)) && isFinite(s);
  }

  const arrodoneix = (n, m) => {
    let eps = (n>=0) ? Number.EPSILON : -Number.EPSILON
    return Math.round( (n+eps) * Math.pow(10, m)) / Math.pow(10, m)
  }

  const calc_preus = (row) => {
    const quantitat = row.querySelector(".quantitat")
    const preu_unit = row.querySelector(".preu-unitat")
    const preu_total = row.querySelector(".preu-total")

    let q = sep_decimal(quantitat.value)
    if(es_numeric(preu_unit.value) && es_numeric(q)) {
      preu_total.value = arrodoneix(preu_unit.value * q, 2).toFixed(2)
    }
    calc_totals()
  }

  const calc_totals = () => {
    const row_totals = document.querySelector("#row-totals")
    const lins_document = document.querySelector("#lins-document")
    if (lins_document.querySelector("div.row-cont select") == null) {
      // no mostrar totals si cap row-cont té select -> estan buits o amb elements eliminats
      row_totals.classList.add("d-none")
      return
    } else {
      row_totals.classList.remove("d-none")
    }

    // mostrar botó afegir inferior si hi han més de 2 línies
    const btn_afegir_inferior = document.querySelector("#btn-afegir-inferior")
    const sel_rows_ok = lins_document.querySelectorAll("div.row-cont select")
    if (sel_rows_ok != null && sel_rows_ok.length > 2) {
      btn_afegir_inferior.classList.remove("d-none")
    } else {
      btn_afegir_inferior.classList.add("d-none")
    }
    
    const total_unitats = document.querySelector("#total-unitats")
    const total_total = document.querySelector("#total-total")
    const subtotal_unitats = document.querySelector("#subtotal-unitats")
    const subtotal_total = document.querySelector("#subtotal-total")
    let tot_u = 0
    let sub_total = 0
    let total_amb_iva = 0
    let iva_tot = {}
    let iva_part = {}
    lins_document.querySelectorAll("div.row-cont").forEach( el => {
      const qty = el.querySelector("input.quantitat")
      const preu_total = el.querySelector("input.preu-total")
      const pct_iva = el.querySelector("input.pct-iva")

      if(qty != null && preu_total != null && pct_iva != null) {
        tot_u += es_numeric(qty.value) ? Number(qty.value) : 0
        sub_total += es_numeric(preu_total.value) ? Number(preu_total.value) : 0
        total_amb_iva += (es_numeric(preu_total.value) && es_numeric(pct_iva.value)) ? Number(preu_total.value) + (Number(preu_total.value) * Number(pct_iva.value) / 100) : 0
      }

      if(pct_iva != null && preu_total != null && es_numeric(preu_total.value) && es_numeric(pct_iva.value)) {
        let p_iva = Number(pct_iva.value)
        let p_tot = Number(preu_total.value)
        iva_tot[p_iva] = p_tot + (iva_tot[p_iva] ? iva_tot[p_iva] : 0)
        iva_part[p_iva] = (p_tot * p_iva / 100) + (iva_part[p_iva] ? iva_part[p_iva] : 0)
      }
    })
    
    subtotal_unitats.textContent = arrodoneix(tot_u, 2).toFixed(2)
    subtotal_total.textContent = arrodoneix(sub_total, 2).toFixed(2)
    // total_unitats.textContent = arrodoneix(tot_u, 2).toFixed(2)
    total_total.textContent = arrodoneix(total_amb_iva, 2).toFixed(2)

    const detall_iva = document.querySelector("#detall-iva")
    let info_iva = []
    Object.keys(iva_tot).forEach( k => {
      info_iva.push( `${iva_tot[k].toFixed(2)} x ${k}% IVA = ${iva_part[k].toFixed(2)}` )
    })
    detall_iva.innerHTML = info_iva.join("<br>")
  }

  const l_doc = document.querySelector("#lins-document")
  l_doc.addEventListener('vanilla-nested:fields-added', function(e){
    // e.type == 'vanilla-nested:fields-added'
    // e.target == container div of the fields
    // e.detail.triggeredBy == the "add" link
    // e.detail.added == the fields wrapper just inserted
    const row_afegit = e.detail.added
    const article = row_afegit.querySelector(".article")
    const quantitat = row_afegit.querySelector(".quantitat")
    const preu_unitat = row_afegit.querySelector(".preu-unitat")
    article.addEventListener("change", article_canviat)
    quantitat.addEventListener("input", qty_canviada)
    preu_unitat.addEventListener("input", preu_unitat_canviat)

    // carregar datepicker caducitat, igual que es fa a l'event d'inici a application.js
    const dt_cad = row_afegit.querySelector(".dt_caducitat")
    if (dt_cad != null) {
      flatpickr(dt_cad, {
        "locale" : Catalan,
        "dateFormat": "Y-m-d",
        "allowInput" : true,
        "altInput" : true,
        "altFormat" : "d-m-Y",
      })
    }

    row_afegit.scrollIntoView()
    calc_totals()
  })
  l_doc.addEventListener('vanilla-nested:fields-removed', function(e){
    calc_totals()
  })

  // rows existents al carregar des de comanda -> s'han d'afegir listeners
  document.querySelectorAll(".row-cont").forEach( el => {
    const article = el.querySelector(".article")
    const quantitat = el.querySelector(".quantitat")
    const preu_unitat = el.querySelector(".preu-unitat")
    article.addEventListener("change", article_canviat)
    quantitat.addEventListener("input", qty_canviada)
    preu_unitat.addEventListener("input", preu_unitat_canviat)
  })

  calc_totals()
})
