document.addEventListener('turbolinks:load', () => {
  const header = document.querySelector('.hdr-info')

  document.addEventListener('scroll', (e) => {
    if(header) {
      if(window.scrollY > 56) {
        header.classList.remove('d-none')
      } else {
        header.classList.add('d-none')
      }
    }
  })
})