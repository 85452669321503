document.addEventListener("turbolinks:load", () => {
  const body_elem = document.querySelector('body')
  if( !( ['pagines'].includes(body_elem.dataset['controller']) && 
         ['inici'].includes(body_elem.dataset['action']) )) { return }
  
  const instruccions = document.getElementById("instruccions")
  if( instruccions != null ) {
    instruccions.addEventListener('shown.bs.collapse', (ev) => {
      ev.target.scrollIntoView()
    })
  }

})