/** @typedef {import("./Chunk")} Chunk */

/** @typedef {import("./ChunkGroup")} ChunkGroup */

/** @typedef {import("./Module")} Module */

/** @typedef {import("./DependenciesBlock")} DependenciesBlock */

/** @typedef {import("./AsyncDependenciesBlock")} AsyncDependenciesBlock */

/**
 * @param {ChunkGroup} chunkGroup the ChunkGroup to connect
 * @param {Chunk} chunk chunk to tie to ChunkGroup
 * @returns {void}
 */
var connectChunkGroupAndChunk = function connectChunkGroupAndChunk(chunkGroup, chunk) {
  if (chunkGroup.pushChunk(chunk)) {
    chunk.addGroup(chunkGroup);
  }
};
/**
 * @param {ChunkGroup} parent parent ChunkGroup to connect
 * @param {ChunkGroup} child child ChunkGroup to connect
 * @returns {void}
 */


var connectChunkGroupParentAndChild = function connectChunkGroupParentAndChild(parent, child) {
  if (parent.addChild(child)) {
    child.addParent(parent);
  }
};
/**
 * @param {Chunk} chunk Chunk to connect to Module
 * @param {Module} module Module to connect to Chunk
 * @returns {void}
 */


var connectChunkAndModule = function connectChunkAndModule(chunk, module) {
  if (module.addChunk(chunk)) {
    chunk.addModule(module);
  }
};
/**
 * @param {Chunk} chunk Chunk being disconnected
 * @param {Module} module Module being disconnected
 * @returns {void}
 */


var disconnectChunkAndModule = function disconnectChunkAndModule(chunk, module) {
  chunk.removeModule(module);
  module.removeChunk(chunk);
};
/**
 * @param {AsyncDependenciesBlock} depBlock DepBlock being tied to ChunkGroup
 * @param {ChunkGroup} chunkGroup ChunkGroup being tied to DepBlock
 * @returns {void}
 */


var connectDependenciesBlockAndChunkGroup = function connectDependenciesBlockAndChunkGroup(depBlock, chunkGroup) {
  if (chunkGroup.addBlock(depBlock)) {
    depBlock.chunkGroup = chunkGroup;
  }
};

exports.connectChunkGroupAndChunk = connectChunkGroupAndChunk;
exports.connectChunkGroupParentAndChild = connectChunkGroupParentAndChild;
exports.connectChunkAndModule = connectChunkAndModule;
exports.disconnectChunkAndModule = disconnectChunkAndModule;
exports.connectDependenciesBlockAndChunkGroup = connectDependenciesBlockAndChunkGroup;