document.addEventListener("turbolinks:load", () => {

  // data-link          edit - ctrl: edit nova finestra/tab
  // data-link-ro       show - ctrl: show nova finestra/tab
  // data-link-ro-ext   show - ctrl: edit

  const parent_exempt_redir = (elem) => {
    while(elem = elem.parentElement) {
      if(elem.matches('.taula-link') || elem.matches('.taula-ico')) {
        // cal assignar classe al <td>, cap parentElement al fer click a un "a" dins un "td" és "a"
        return true
      }
    }
    return false
  }

  document.querySelectorAll("tr[data-link]").forEach(tr => 
    tr.addEventListener("click", (ev) => {
      if(parent_exempt_redir(ev.target)) { return }
      if (ev.ctrlKey) {
        window.open(tr.dataset.link + "/edit")
      } else {
        location.href = tr.dataset.link + "/edit"
      }
    }))

  document.querySelectorAll("tr[data-link-ro]").forEach(tr => 
    tr.addEventListener("click", (ev) => {
      if(parent_exempt_redir(ev.target)) { return }
      if (ev.ctrlKey) {
        window.open(tr.dataset.linkRo)
      } else {
        location.href = tr.dataset.linkRo
      }
    }))

  document.querySelectorAll("tr[data-link-ro-ext]").forEach(tr => 
    tr.addEventListener("click", (ev) => {
      if(parent_exempt_redir(ev.target)) { return }
      if (ev.ctrlKey) {
        location.href = tr.dataset.linkRoExt + "/edit"
      } else {
        location.href = tr.dataset.linkRoExt
      }
    }))

})
